<template>
  <div>
    <v-app-bar
      :elevation="0"
      height="72"
      class="user-select-none"
      :class="drawer ? '' : 'app-bar'"
    >
      <v-container class="d-flex">
        <!-- SPIL Logo -->
        <router-link
          to="/"
          @click="() => setActivePage('/')"
          class="text-decoration-none"
        >
          <img
            src="@/assets/spil_logo.svg"
            alt="SPIL Logo"
            width="70"
            height="45"
          />
        </router-link>

        <!-- Menu List -->
        <div class="w-100 d-none d-lg-flex justify-end align-center mr-5">
          <div v-for="(menu, idx) in navMenus" :key="idx" class="ml-12">
            <!-- Dropdown Menu -->
            <div
              v-if="containsKey(menu, 'items')"
              :id="menu.label"
              class="text-nav cursor-pointer d-flex align-center"
              :class="
                menu.key != 'lang' &&
                (menu.items
                  .map((item) => {
                    if (containsKey(item, 'href')) return item.href[currLang];
                  })
                  .includes(activePage) ||
                  activePage.includes(
                    this.$root.lang(menu.label).toLowerCase()
                  ))
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
              role="button"
              aria-hidden="true"
            >
              <img
                v-if="menu.key == 'lang'"
                :src="currLangImg"
                alt="Lang Flag"
                width="20"
                height="20"
                class="mr-2"
              />
              {{ this.$root.lang(menu.label) }}
              <img
                src="@/assets/images/icons/arrow_down.svg"
                alt="dropdown icon"
                class="ml-2 arrow-icon"
                style="transition: 0.5s ease"
                :style="
                  menuOpen[idx] != null && menuOpen[idx] == true
                    ? 'transform: rotate(-180deg);'
                    : ''
                "
              />
            </div>

            <div v-else>
              <!-- Login -->
              <div v-if="menu?.key">
                <LoginModal>
                  {{ this.$root.lang(menu.label) }}
                </LoginModal>
              </div>

              <!-- Only My SPIL menu -->
              <div
                v-else-if="
                  menu.label == 'navMySpil' &&
                  activePage == menu.href[currLang] &&
                  token
                "
                class="text-decoration-none text-nav cursor-pointer"
                :class="
                  activePage == menu.href[currLang]
                    ? 'text-secondary'
                    : 'text-text-default nav-hover'
                "
                @click="loginSSO"
              >
                {{ this.$root.lang(menu.label) }}
              </div>

              <!-- Basic Menu -->
              <router-link
                v-else
                :to="menu.href[currLang]"
                @click="setActivePage(menu.href[currLang])"
                class="text-decoration-none text-nav"
                :class="
                  activePage == menu.href[currLang]
                    ? 'text-secondary'
                    : 'text-text-default nav-hover'
                "
              >
                {{ this.$root.lang(menu.label) }}
              </router-link>
            </div>
          </div>
        </div>

        <!-- SEARCH -->
        <!-- <div class="ml-8 d-none d-lg-flex align-center">
          <img src="@/assets/images/icons/search.svg" class="cursor-pointer" />
        </div> -->

        <!-- MOBILE -->
        <div class="w-100 d-flex d-lg-none justify-end">
          <v-btn
            icon="mdi mdi-menu"
            :ripple="false"
            @click="drawer = !drawer"
            aria-label="drawer button"
          />
        </div>
      </v-container>
    </v-app-bar>

    <!-- MENU DROPDOWN LIST -->
    <div v-for="(menu, idx) in navMenus" :key="idx">
      <v-menu
        v-if="containsKey(menu, 'items')"
        :activator="'#' + menu.label"
        v-model="menuOpen[idx]"
        location="center"
        style="z-index: 0"
      >
        <v-list class="mt-14 rounded-b-xl" elevation="0">
          <v-list-item
            v-for="(item, idx) in menu.items"
            :key="idx"
            :value="idx"
            class="pa-0"
          >
            <!-- Change Language -->
            <div
              v-if="containsKey(item, 'icon')"
              @click="setLanguage(item.value)"
              class="text-decoration-none text-nav text-text-default d-flex align-center nav-hover px-4"
              style="height: 40px"
            >
              <img
                :src="item.icon"
                alt="Lang Flag"
                width="20"
                height="20"
                class="mr-3"
              />
              {{ this.$root.lang(item.label) }}
            </div>

            <!-- Regular Options -->
            <a
              v-else-if="item?.external"
              :href="item.href[currLang]"
              target="_blank"
              class="text-decoration-none text-nav d-flex align-center text-text-default nav-hover px-4"
              style="height: 40px"
              @click="
                saveTrackAnalytics(
                  'CLICK BOOK OUR SERVICE',
                  'Click the navbar menu to be redirected to MY SPIL',
                  '',
                  '',
                  '',
                  '',
                  ''
                )
              "
            >
              {{ this.$root.lang(item.label) }}
            </a>
            <router-link
              v-else
              :to="item.href[currLang]"
              @click="setActivePage(item.href[currLang])"
              class="text-decoration-none text-nav d-flex align-center px-4"
              :class="
                activePage == item.href[currLang] ||
                activePage.includes(this.$root.lang(item.label).toLowerCase())
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
              style="height: 40px"
            >
              {{ this.$root.lang(item.label) }}
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- MENU DRAWER -->
    <v-navigation-drawer
      v-model="drawer"
      location="top"
      class="w-100 h-100 d-block d-lg-none"
      color="#eee"
    >
      <v-container class="mt-6">
        <v-list-item
          v-for="(menu, idx) in navMenus"
          :key="idx"
          class="cursor-pointer text-text-default font-weight-medium pa-0"
        >
          <div>
            <!-- Dropdown Menu -->
            <div
              v-if="containsKey(menu, 'items')"
              @click="() => (menuOpen[idx] = true)"
              :id="menu.label"
              class="text-nav cursor-pointer d-flex justify-space-between align-center"
              :class="
                menu.items
                  .map((item) => {
                    if (containsKey(item, 'href')) return item.href[currLang];
                  })
                  .includes(activePage) ||
                activePage.includes(menu.label.toLowerCase())
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
              style="height: 40px"
            >
              <div class="d-flex align-center">
                <img
                  v-if="menu.key == 'lang'"
                  :src="currLangImg"
                  alt="Lang Flag"
                  width="20"
                  height="20"
                  class="mr-2"
                />
                {{ this.$root.lang(menu.label) }}
              </div>

              <img
                src="@/assets/images/icons/arrow_down.svg"
                alt="dropdown icon"
                class="ml-2 arrow-icon"
                style="transition: 0.5s ease"
                :style="
                  menuOpen[idx] != null && menuOpen[idx] == true
                    ? 'transform: rotate(-180deg);'
                    : ''
                "
              />
            </div>

            <!-- Login -->
            <div v-else-if="menu?.key">
              <LoginModal>
                {{ this.$root.lang(menu.label) }}
              </LoginModal>
            </div>

            <!-- Only My SPIL menu -->
            <div
              v-else-if="
                menu.label == 'navMySpil' &&
                activePage == menu.href[currLang] &&
                token
              "
              class="text-decoration-none text-nav"
              :class="
                activePage == menu.href[currLang]
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
              @click="loginSSO"
            >
              {{ this.$root.lang(menu.label) }}
            </div>

            <!-- Basic Menu -->
            <router-link
              v-else
              :to="menu.href[currLang]"
              @click="setActivePage(menu.href[currLang])"
              class="text-decoration-none text-nav"
              :class="
                activePage == menu.href[currLang]
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
            >
              <div>{{ this.$root.lang(menu.label) }}</div>
            </router-link>
          </div>

          <!-- Mobile Menu List -->
          <div v-show="menuOpen[idx]">
            <div
              v-for="(item, idx) in menu.items"
              :key="idx"
              class="d-flex align-center ml-4 my-2"
            >
              <!-- Change Language  -->
              <div
                v-if="containsKey(item, 'icon')"
                @click="setLanguage(item.value)"
                class="text-decoration-none text-nav text-text-subdued d-flex align-center nav-hover w-100"
              >
                <img
                  :src="item.icon"
                  alt="Lang Flag"
                  width="20"
                  height="20"
                  class="mr-3"
                />
                {{ this.$root.lang(item.label) }}
              </div>

              <!-- Regular Options -->
              <a
                v-else-if="item?.external"
                :href="item.href[currLang]"
                target="_blank"
                class="text-decoration-none text-nav d-flex align-center text-text-subdued nav-hover w-100"
                style="height: 40px"
                @click="
                  saveTrackAnalytics(
                    'CLICK BOOK OUR SERVICE',
                    'Click the navbar menu to be redirected to MY SPIL',
                    '',
                    '',
                    '',
                    '',
                    ''
                  )
                "
              >
                {{ this.$root.lang(item.label) }}
              </a>

              <router-link
                v-else
                :to="item.href[currLang]"
                @click="setActivePage(item.href[currLang])"
                class="text-decoration-none text-nav d-flex align-center w-100"
                :class="
                  activePage == item.href[currLang] ||
                  activePage.includes(this.$root.lang(item.label).toLowerCase())
                    ? 'text-secondary'
                    : 'text-text-subdued nav-hover'
                "
              >
                {{ this.$root.lang(item.label) }}
              </router-link>
            </div>
          </div>
        </v-list-item>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/GeneralStore";
import { useTrackAnalyticsStore } from "@/stores/TrackAnalyticsStore";
import { useUserStore } from "@/stores/UserStore";

import LoginModal from "./Base/LoginModal.vue";
import MyPlugin from "@/plugins/plugin";
import { getEnv } from "@/configs/config";
import { routes } from "@/router";

export default {
  name: "HeaderNav",
  components: {
    LoginModal,
  },
  data() {
    return {
      drawer: false,
      menuOpen: [],
      currLang: "ind",
      navMenus: [
        {
          label: "navHome",
          href: { ind: "/id", eng: "/en" },
        },
        {
          label: "navCommitment",
          href: { ind: "/id/keberlanjutan", eng: "/en/sustainability" },
        },
        {
          label: "navCompany",
          items: [
            {
              label: "navAbout",
              href: { ind: "/id/tentang-spil", eng: "/en/about-spil" },
            },
            {
              label: "navHistory",
              href: {
                ind: "/id/perusahaan/sejarah",
                eng: "/en/company/history",
              },
            },
            {
              label: "navGroup",
              href: { ind: "/id/perusahaan/grup", eng: "/en/company/group" },
            },
            {
              label: "navCareer",
              href: { ind: "/id/karir", eng: "/en/careers" },
            },
          ],
        },
        {
          label: "navServices",
          items: [
            {
              label: "navServicesType",
              href: { ind: "/id/layanan", eng: "/en/services" },
            },
            {
              label: "navGuide",
              href: { ind: "/id/layanan/panduan", eng: "/en/service/guide" },
            },
            {
              label: "navRoute",
              href: {
                ind: "/id/layanan/rute/sumatra",
                eng: "/en/service/route/sumatra",
              },
            },
            {
              label: "navBookService",
              external: true,
              href: { ind: "", eng: "" },
            },
          ],
        },
        {
          label: "navContact",
          href: { ind: "/id/kontak", eng: "/en/contact" },
        },
        {
          label: "navMySpil",
          href: { ind: "/id/myspil", eng: "/en/myspil" },
        },
        {
          label: "navLogin",
          key: "login",
        },
        {
          key: "lang",
          label: "navLang",
          items: [
            {
              icon: require("@/assets/images/icons/indonesia.png"),
              label: "navID",
              value: "indonesia",
            },
            {
              icon: require("@/assets/images/icons/united-kingdom.png"),
              label: "navENG",
              value: "english",
            },
          ],
        },
      ],
      otherMenus: [
        {
          href: { ind: "/id/berita", eng: "/en/news" },
        },
        {
          href: { ind: "/id/spildex", eng: "/en/spildex" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["activePage"]),
    ...mapState(useUserStore, ["token"]),

    currLangImg() {
      return MyPlugin.LangPlugins.isIndonesia()
        ? require("@/assets/images/icons/indonesia.png")
        : require("@/assets/images/icons/united-kingdom.png");
    },

    mySPILURL() {
      return getEnv(process.env.MYSPIL_URL);
    },
  },
  watch: {
    token() {
      // Set myspil url
      const myspilmenu = this.navMenus
        .find((d) => d.label == "navServices")
        .items.find((d) => d.label == "navBookService");
      myspilmenu.href = {
        ind: this.token
          ? `${this.mySPILURL}/AuthMiddleware/submitloginsso?ts=${this.token}`
          : this.mySPILURL,
        eng: this.token
          ? `${this.mySPILURL}/AuthMiddleware/submitloginsso?ts=${this.token}`
          : this.mySPILURL,
      };
    },
  },
  methods: {
    ...mapActions(useGeneralStore, ["toggleLanguage", "setActivePage"]),

    containsKey(obj, key) {
      return MyPlugin.OtherPlugins.containsKey(obj, key);
    },
    setLanguage(lang) {
      let currActiveMenu = this.activePage;
      const newLang = lang == "indonesia" ? "ind" : "eng";

      this.navMenus.forEach((menu) => {
        if (menu.key == "lang") return;

        if (menu.items) {
          menu.items.forEach((item) => {
            if (item.href[this.currLang] == this.activePage) {
              currActiveMenu = item.href[newLang];
            }
          });
        }

        if (menu.href && menu.href[this.currLang] == this.activePage) {
          currActiveMenu = menu.href[newLang];
        }
      });

      // If currActiveMenu equals null > the current menu is on footer nav
      if (!currActiveMenu) {
        this.otherMenus.forEach((menu) => {
          if (menu.href[this.currLang] == this.activePage) {
            currActiveMenu = menu.href[newLang];
          }
        });
      }

      this.currLang = newLang;
      this.setActivePage(currActiveMenu);
      this.toggleLanguage(lang);

      // Redirect to active language path
      const curPage = routes.find((route) => {
        if (route.name === "NotFound") return false;
        if (route.name != this.$route.matched[0].name) return false;

        const currentPath = this.$route.matched[0].path.split(")")[1];
        const routePath = route.path.split(")")[1];
        const routeAlias = route?.alias?.split(")")[1];

        if (!routePath || !routeAlias) {
          // If route is home
          if (["/id", "/en"].includes(this.$route.path)) {
            return true;
          }

          return false;
        }

        // Check if the route path matches
        if (currentPath.includes(routePath)) return true;
        if (currentPath.includes(routeAlias)) return true;

        return false;
      });

      if (curPage) {
        let targetPath;

        if (MyPlugin.LangPlugins.isIndonesia()) {
          targetPath = curPage?.alias?.replace("/:lang(id)", "/id");
        } else {
          targetPath = curPage.path.replace("/:lang(en)", "/en");
        }

        // Replace slug
        if (targetPath.includes(":")) {
          targetPath = this.formatURL(this.$route.path, targetPath);
        }

        // Handle if en and id has same route name
        const idPathName = curPage.path.split(")")[1];
        const enPathName = curPage?.alias?.split(")")[1];
        if (idPathName && enPathName && idPathName == enPathName) {
          window.location.href = window.location.origin + targetPath;
        } else {
          this.$router.push(targetPath);
        }
      }
    },
    formatURL(inputURL, format) {
      const inputParts = inputURL.split("/").filter(Boolean);
      const formatParts = format.split("/").filter(Boolean);

      let formattedURL = [];
      let inputIndex = 0;

      for (let part of formatParts) {
        if (part.startsWith(":")) {
          formattedURL.push(inputParts[inputIndex]);
        } else {
          formattedURL.push(part);
        }
        inputIndex++;
      }

      return "/" + formattedURL.join("/");
    },
    saveTrackAnalytics(action, remark, val1, val2, val3, val4, val5) {
      let userStore = useUserStore();
      let trackAnalyticsStore = useTrackAnalyticsStore();

      let pathName =
        this.$route && this.$route.fullPath ? this.$route.fullPath : "";
      let pathParams =
        this.$route && this.$route.params
          ? JSON.stringify(this.$route.params)
          : "";
      let pathModule =
        this.$route && this.$route.meta && this.$route.meta.module
          ? this.$route.meta.module
          : "";

      let saveTrackAnalyticsParam = {
        user_id: userStore.$state.userId,
        customer_id: userStore.$state.customerId,
        action: action,
        module: pathModule,
        remark: remark,
        ippublic: "",
        iplocal: "",
        value1: val1 ? val1 : pathName,
        value2: val2 ? val2 : pathParams,
        value3: val3 ? val3 : "",
        value4: val4 ? val4 : "",
        value5: val5 ? val5 : "",
      };
      trackAnalyticsStore.saveTrackAnalytics(saveTrackAnalyticsParam);
    },
    loginSSO() {
      window.open(
        `${this.mySPILURL}/AuthMiddleware/submitloginsso?ts=${this.token}`,
        "_blank"
      );
    },
  },
  mounted() {
    this.currLang = MyPlugin.LangPlugins.isIndonesia() ? "ind" : "eng";

    const pathname = window.location.pathname;
    this.setActivePage(pathname);

    // Set myspil url
    const myspilmenu = this.navMenus
      .find((d) => d.label == "navServices")
      .items.find((d) => d.label == "navBookService");
    myspilmenu.href = {
      ind: this.token
        ? `${this.mySPILURL}/AuthMiddleware/submitloginsso?ts=${this.token}`
        : this.mySPILURL,
      eng: this.token
        ? `${this.mySPILURL}/AuthMiddleware/submitloginsso?ts=${this.token}`
        : this.mySPILURL,
    };
  },
};
</script>

<style scoped>
.app-bar {
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(8px);
}
.nav-hover:hover {
  text-shadow: 0 0 1px #333, 0 0 1px #333;
}
.arrow-icon {
  width: 12px;
  height: 12px;
  aspect-ratio: 1;
}
</style>
