const getEnv = (env) => {
  const splitEnvWithComment = env.split("#");
  if (splitEnvWithComment.length > 1) {
    const envValue = splitEnvWithComment[0].split('"');
    return envValue[1];
  } else {
    return env;
  }
};

export { getEnv };
