<template>
  <v-alert
    v-if="alert.open"
    :title="alert.title"
    :text="alert.message"
    :type="alert.type.toLowerCase()"
    :color="alert_color[alert.type.toLowerCase()].main"
    class="alert"
    :style="{
      borderRight: `8px solid ${alert_color[alert.type.toLowerCase()].border}`,
    }"
  />
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useGeneralStore } from "@/stores/GeneralStore.js";

export default {
  name: "BaseAlert",
  data() {
    return {
      alert_color: {
        success: {
          main: "#4C9B67",
          border: "#00872F",
        },
        info: {
          main: "#4091d7",
          border: "#0075D6",
        },
        warning: {
          main: "#b36800",
          border: "#874C00",
        },
        error: {
          main: "#b34045",
          border: "#9B2B2F",
        },
      },
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["alert"]),
  },
  methods: {
    ...mapActions(useGeneralStore, ["showAlert"]),
  },
};
</script>

<style scoped>
::v-deep .v-alert__content {
  color: white;
}
::v-deep .v-alert-title {
  font-weight: 800;
}

.alert {
  position: fixed; /* Ubah ke fixed */
  z-index: 9999;
  min-width: 240px;
  max-width: 500px;
  right: 0;
  top: 10px;
  margin: 10px;
}
</style>
