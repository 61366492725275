<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ props: activatorProps }">
      <div
        v-if="!user_id"
        v-bind="activatorProps"
        class="login text-nav cursor-pointer text-primary-darken-1"
      >
        <slot></slot>
      </div>

      <v-menu v-else v-model="menuOpen" style="z-index: 0">
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="d-flex align-center cursor-pointer text-primary"
            style="height: 40px"
          >
            <div class="flex-grow-1 d-flex">
              <v-icon icon="mdi mdi-account-circle-outline"></v-icon>

              <div class="text-nav font-weight-bold mx-2">
                {{ user_id }}
              </div>
            </div>

            <img
              src="@/assets/images/icons/arrow_down.svg"
              alt="dropdown icon"
              class="ml-2"
              style="transition: 0.5s ease"
              :style="
                menuOpen != null && menuOpen == true
                  ? 'transform: rotate(-180deg);'
                  : ''
              "
            />
          </div>

          <!-- Logout Button -->
          <div
            v-if="menuOpen"
            @click="logout"
            class="d-flex d-md-none align-center ml-4 my-3"
          >
            <v-icon icon="mdi mdi-exit-to-app" class="mr-2"></v-icon>
            <div class="text-text-default font-weight-medium">
              {{ this.$root.lang("logout") }}
            </div>
          </div>
        </template>

        <v-list class="d-none d-md-block mt-4 rounded-b-xl" elevation="0">
          <v-list-item @click="logout">
            <div class="d-flex align-center">
              <v-icon icon="mdi mdi-exit-to-app" class="mr-2"></v-icon>
              <div class="text-text-default font-weight-medium">
                {{ this.$root.lang("logout") }}
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-card class="pa-6">
      <!-- Header -->
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6 text-primary-darken-1 mb-2">
          {{ this.$root.lang("navLogin") }}
        </div>

        <v-icon
          icon="mdi mdi-close-circle-outline"
          style="color: gray"
          @click="dialog = false"
        />
      </div>

      <!-- Input Field -->
      <v-text-field
        v-model="credential.username"
        variant="underlined"
        placeholder="Username/Phone"
        :error-messages="error.username"
        v-debounce:500ms="() => valueUpdated('username')"
        @keyup.enter="login"
      ></v-text-field>

      <v-text-field
        v-model="credential.password"
        variant="underlined"
        placeholder="Password"
        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :error-messages="error.password"
        @click:append-inner="showPassword = !showPassword"
        v-debounce:500ms="() => valueUpdated('password')"
        @keyup.enter="login"
      ></v-text-field>

      <!-- CTA -->
      <div class="d-flex justify-end mt-2">
        <v-btn
          variant="text"
          class="mr-2 rounded-pill"
          :href="`${mySPILURL}/Front/reg`"
          target="_blank"
        >
          {{ this.$root.lang("signUp") }}
        </v-btn>

        <v-btn
          variant="flat"
          color="primary"
          class="rounded-pill"
          :loading="loading"
          @click="login"
        >
          {{ this.$root.lang("navLogin") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getEnv } from "@/configs/config";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  data() {
    return {
      credential: {
        username: null,
        password: null,
      },
      error: {
        username: null,
        password: null,
      },
      showPassword: false,
      loading: false,
      dialog: false,
      menuOpen: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ["user_id"]),

    mySPILURL() {
      return getEnv(process.env.MYSPIL_URL);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.credential.username = null;
        this.credential.password = null;

        this.error.username = null;
        this.error.password = null;
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "userLogin",
      "destroyTokenLogin",
      "getUserIP",
    ]),

    valueUpdated(key) {
      if (this.credential[key]) this.error[key] = null;
    },
    async login() {
      this.loading = true;

      // Check if field is empty
      let err = false;
      Object.keys(this.error).map((key) => {
        if (!this.credential[key]) {
          this.error[key] = "This field is required!";
          err = true;
        }
      });
      if (err) return (this.loading = false);

      // Main process
      const ip = await this.getUserIP();
      const params = {
        username: this.credential.username,
        password: this.credential.password,
        ipaddress: ip ?? "0.0.0.0",
        source: "SPILCOID",
      };

      const res = await this.userLogin(params);
      if (res) {
        this.dialog = false;
      }

      this.loading = false;
    },
    async logout() {
      await this.destroyTokenLogin();
    },
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
  min-width: 120px;
}

.login:hover {
  text-shadow: 0 0 1px #29682e, 0 0 1px #29682e;
}
</style>
