<template>
  <v-app class="d-block">
    <Alert />

    <Header />
    <slot />
    <Footer v-if="contentReady" />

    <!-- Contact Us -->
    <ContactUsDialog />
  </v-app>
</template>

<script>
import Header from "../components/HeaderNav.vue";
import Footer from "../components/FooterNav.vue";
import ContactUsDialog from "../components/ContactUsDialog.vue";
import Alert from "../components/Base/Alert.vue";

export default {
  name: "AppLayout",
  components: { Header, Footer, ContactUsDialog, Alert },
  data() {
    return {
      contentReady: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.contentReady = true;
    }, 1000);
  },
};
</script>
