import { defineStore } from "pinia";
import { watchEffect } from "vue";
import { useUserStore } from "@/stores/UserStore";

export const useGeneralStore = defineStore("GeneralStore", {
  state: () => ({
    choosedLanguage: "indonesia",
    activePage: "/",

    alert: {
      open: false,
      type: "",
      title: "",
      message: "",
    },
    alertTimeout: null,
    inactivityTimeout: null,
  }),
  getters: {},
  actions: {
    toggleLanguage(state) {
      this.choosedLanguage = state;
    },
    setActivePage(state) {
      const user = useUserStore();

      this.activePage = state;

      if (user.token) {
        this.resetInactivityTimer();
      }

      // Sometimes the alert is stuck force close it when change page
      this.alert = {
        open: false,
        type: "",
        title: "",
        message: "",
      };
    },

    showAlert(data) {
      this.alert = { ...data, open: true };

      // Clear any existing timeout
      if (this.alertTimeout) {
        clearTimeout(this.alertTimeout);
      }

      // Set a new timeout to close the alert
      this.alertTimeout = setTimeout(() => {
        this.alert = {
          open: false,
          type: "",
          title: "",
          message: "",
        };
      }, 3000);
    },

    resetInactivityTimer() {
      if (this.inactivityTimeout) {
        clearTimeout(this.inactivityTimeout);
      }

      this.inactivityTimeout = setTimeout(() => {
        this.handleInactivityAction();
      }, 15 * 60 * 1000);
    },

    async handleInactivityAction() {
      const user = useUserStore();

      await user.destroyTokenLogin();

      this.showAlert({
        type: "warning",
        title: "Inactivity Alert",
        message: "You were logged out due to inactivity.",
      });
    },
  },
  setup() {
    watchEffect(() => {
      if (!this.alert.open && this.alertTimeout) {
        clearTimeout(this.alertTimeout);
        this.alertTimeout = null;
      }
    });
  },
  persist: true,
});
