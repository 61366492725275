import { defineStore } from "pinia";
import mainAPI from "@/api/mainAPI";
import { useGeneralStore } from "@/stores/GeneralStore";
import { getEnv } from "@/configs/config";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    token: null,
    user_id: null,
  }),
  getters: {},
  actions: {
    setPurpose(state) {
      this.purposeMsg = this.purposeMsg.concat(state);
    },

    async userLogin(params) {
      const general = useGeneralStore();
      const isProd = getEnv(process.env.PRODUCTION).toLowerCase() == "true";

      let header = {
        Authorization: "Basic Z3Vlc3Q6MTIzNA==",
      };

      let body = {
        apidata: {
          apirequest: isProd ? "USERLOGINSSO" : "CHECKLOGIN",
          appversion: "1",
          userid: params.username,
          source: "SPILCOID",
        },
        params: params,
      };

      let result;
      if (getEnv(process.env.PRODUCTION).toLowerCase() == "true") {
        const res = await mainAPI.requestHandler(header, body);
        result = res.data.data;
      } else {
        const res = await mainAPI.requestHandlerMYSPIL(header, body);
        result = res.data;
      }

      if (result.isSuccess == "not-ok") {
        general.showAlert({
          type: "error",
          title: "Login Failed",
          message: result.msg,
        });
        return false;
      }

      this.token = result.data[0].token;
      this.user_id = result.data[0].users.split(";")[2];

      general.resetInactivityTimer();

      general.showAlert({
        type: "success",
        title: "Login Success",
        message: "You have successfully logged in!",
      });

      return true;
    },

    async destroyTokenLogin() {
      const general = useGeneralStore();
      const isProd = getEnv(process.env.PRODUCTION).toLowerCase() == "true";

      let header = {
        Authorization: "Basic Z3Vlc3Q6MTIzNA==",
      };

      let body = {
        apidata: {
          apirequest: isProd ? "USERLOGOUTSSO" : "DESTROYSSOTOKEN",
          appversion: "1",
          userid: this.user_id,
          source: "SPILCOID",
        },
        params: {
          token: this.token,
          source: "SPILCOID",
        },
      };

      let result;
      if (getEnv(process.env.PRODUCTION).toLowerCase() == "true") {
        const res = await mainAPI.requestHandler(header, body);
        result = res.data.data;
      } else {
        const res = await mainAPI.requestHandlerMYSPIL(header, body);
        result = res.data;
      }

      if (result.isSuccess == "not-ok") {
        general.showAlert({
          type: "error",
          title: "Login Failed",
          message: result.msg,
        });
        return false;
      }

      general.showAlert({
        type: "success",
        title: "Logout Success",
        message: "You have successfully logged out.",
      });

      this.token = null;
      this.user_id = null;

      return true;
    },

    async getUserIP() {
      return await mainAPI.getUserIP();
    },
  },
  persist: {
    storage: sessionStorage,
    enabled: true,
  },
});
