import { event } from "vue-gtag";

const gtagReportConversion = (url) => {
  event("conversion", {
    send_to: "AW-16533594815/RHwyCP3OmqUaEL_F6ss9",
    value: 1.0,
    currency: "IDR",
    event_callback: () => {
      if (typeof url !== "undefined") {
        window.location = url;
      }
    },
  });

  return false;
};

const GtagPlugins = { gtagReportConversion };

export default GtagPlugins;
