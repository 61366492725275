import { createRouter, createWebHistory } from "vue-router";
import { useTrackAnalyticsStore } from "@/stores/TrackAnalyticsStore";
import { useUserStore } from "@/stores/UserStore";
import { useGeneralStore } from "@/stores/GeneralStore";

function lazyLoad(view) {
  return () => import(`@/views/pages/${view}.vue`);
}

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: lazyLoad("NotFound"),
    meta: {
      module: "NOTFOUND",
    },
  },
  {
    path: "/:lang(en)",
    alias: "/:lang(id)",
    name: "home",
    component: lazyLoad("HomePage"),
    meta: {
      module: "HOME",
    },
  },
  {
    path: "/:lang(en)/sustainability",
    alias: "/:lang(id)/keberlanjutan",
    name: "commitment",
    component: lazyLoad("CommitmentPage"),
    meta: {
      module: "COMMITMENT",
    },
  },
  {
    path: "/:lang(en)/about-spil",
    alias: "/:lang(id)/tentang-spil",
    name: "about-company",
    component: lazyLoad("company/AboutCompanyPage"),
    meta: {
      module: "COMPANY",
    },
  },
  {
    path: "/:lang(en)/company/history",
    alias: "/:lang(id)/perusahaan/sejarah",
    name: "history-company",
    component: lazyLoad("company/HistoryCompanyPage"),
    meta: {
      module: "COMPANY",
    },
  },
  {
    path: "/:lang(en)/company/group",
    alias: "/:lang(id)/perusahaan/grup",
    name: "group-company",
    component: lazyLoad("company/GroupCompanyPage"),
    meta: {
      module: "COMPANY",
    },
  },
  {
    path: "/:lang(en)/careers",
    alias: "/:lang(id)/karir",
    name: "career-company",
    component: lazyLoad("company/CareerCompanyPage"),
    meta: {
      module: "COMPANY",
    },
  },
  {
    path: "/:lang(en)/company/career/:slug",
    alias: "/:lang(id)/perusahaan/karir/:slug",
    name: "career-detail-company",
    component: lazyLoad("company/CareerDetailCompanyPage"),
    meta: {
      module: "COMPANY",
    },
  },
  {
    path: "/:lang(en)/services",
    alias: "/:lang(id)/layanan",
    name: "type-service",
    component: lazyLoad("service/TypeServicePage"),
    meta: {
      module: "SERVICE",
    },
  },
  {
    path: "/:lang(en)/service/guide",
    alias: "/:lang(id)/layanan/panduan",
    name: "guide-service",
    component: lazyLoad("service/GuideServicePage"),
    meta: {
      module: "SERVICE",
    },
  },
  {
    path: "/:lang(en)/service/guide/:slug",
    alias: "/:lang(id)/layanan/panduan/:slug",
    name: "guide-detail-service",
    component: lazyLoad("service/GuideDetailServicePage"),
    meta: {
      module: "SERVICE",
    },
  },
  {
    path: "/:lang(en)/service/guide/:topicid/:subtopicid",
    alias: "/:lang(id)/layanan/panduan/:topicid/:subtopicid",
    name: "guide-detail-service-new",
    component: lazyLoad("service/GuideDetailServicePage"),
    meta: {
      module: "SERVICE",
    },
  },
  {
    path: "/:lang(en)/service/route/:slug",
    alias: "/:lang(id)/layanan/rute/:slug",
    name: "route-service",
    component: lazyLoad("service/RouteServicePage"),
    meta: {
      module: "SERVICE",
    },
  },
  {
    path: "/:lang(en)/contact",
    alias: "/:lang(id)/kontak",
    name: "contact-us",
    component: lazyLoad("ContactUsPage"),
    meta: {
      module: "CONTACT",
    },
  },
  {
    path: "/:lang(en)/myspil",
    alias: "/:lang(id)/myspil",
    name: "myspilcom",
    component: lazyLoad("mySPILcomPage"),
    meta: {
      module: "MYSPILCOM",
    },
  },
  {
    path: "/:lang(en)/news",
    alias: "/:lang(id)/berita",
    name: "news",
    component: lazyLoad("news/NewsPage"),
    meta: {
      module: "NEWS",
    },
  },
  {
    path: "/:lang(en)/news/:slug",
    alias: "/:lang(id)/berita/:slug",
    name: "news-detail",
    component: lazyLoad("news/NewsDetailPage"),
    meta: {
      module: "NEWS",
    },
  },
  {
    path: "/:lang(en)/insight",
    alias: "/:lang(id)/wawasan",
    name: "outlook",
    component: lazyLoad("outlook/OutlookPage"),
    meta: {
      module: "OUTLOOK",
    },
  },
  {
    path: "/:lang(en)/insight/:slug",
    alias: "/:lang(id)/wawasan/:slug",
    name: "outlook-detail",
    component: lazyLoad("outlook/OutlookDetailPage"),
    meta: {
      module: "OUTLOOK",
    },
  },
  {
    path: "/:lang(en)/spildex",
    alias: "/:lang(id)/spildex",
    name: "spildex",
    component: lazyLoad("SPILDexPage"),
    meta: {
      module: "SPILDEX",
    },
  },
];

const old_routes = [
  {
    path: "/routes-2",
    redirect: "/id/layanan/rute/sumatra",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...old_routes],
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Change language based on url language
  const generalStore = useGeneralStore();

  if (to.path.startsWith("/id")) {
    generalStore.toggleLanguage("indonesia");
  } else if (to.path.startsWith("/en")) {
    generalStore.toggleLanguage("english");
  }

  // If url doesnt have language prefix or have it but the rest url is different lang
  // Fix the url so it will related to same lang
  const splitedUrl = to.path.split("/");
  let targetPath, urlLang;

  if (to.path.startsWith("/id") || to.path.startsWith("/en")) {
    urlLang = splitedUrl[1];
    targetPath = splitedUrl[2];
  } else {
    targetPath = splitedUrl[1];
  }

  // Get target lang based on target path language
  const idPath = routes.find((d) => d?.alias?.includes(targetPath));
  const enPath = routes.find((d) => d.path.includes(targetPath));

  let targetLang;
  if (idPath) targetLang = "id";
  if (enPath) targetLang = "en";

  // If url doesnt have url add the language prefix
  if (targetLang && !urlLang) {
    if (to.fullPath != "/") {
      return next(`/${targetLang}${to.path}`);
    } else {
      return next(`/${targetLang}`);
    }
  }

  // If have wrong lang but the url route is exist
  if (targetLang && targetLang != urlLang) {
    // Check if en route name is identic with id route name
    const routeObj = targetLang == "id" ? idPath : enPath;
    const isIdenticName =
      routeObj.path.split(")")[1] == routeObj?.alias?.split(")")[1];
    if (isIdenticName) return next();

    // Route is different but the language is wrong
    splitedUrl[1] = targetLang;
    return next(splitedUrl.join("/"));
  }

  // Redirect to home if url is not exist
  if (to.meta.module == "NOTFOUND") return next("/id");

  // Save track analytics when enter page
  let trackAnalyticsStore = useTrackAnalyticsStore();
  let userStore = useUserStore();

  let pathName = to && to.fullPath ? to.fullPath : "";
  let pathParams = to && to.params ? JSON.stringify(to.params) : "";
  let pathModule = to && to.meta && to.meta.module ? to.meta.module : "";

  let saveTrackAnalyticsParam = {
    user_id: userStore.$state.userId,
    customer_id: userStore.$state.customerId,
    action: "PAGE ENTER",
    module: pathModule,
    remark: "",
    ippublic: "",
    iplocal: "",
    value1: pathName,
    value2: pathParams,
    value3: "",
    value4: "",
    value5: "",
  };

  trackAnalyticsStore.saveTrackAnalytics(saveTrackAnalyticsParam);

  next();
});

export { router, routes };
