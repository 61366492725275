import { getEnv } from "@/configs/config";
import axios from "axios";

const mainAPI = {
  requestHandler(header, body) {
    return axios
      .post(
        `${getEnv(process.env.BASE_API_URL)}/requesthandler`,
        JSON.stringify(body), //this is the request body
        {
          headers: header,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },

  requestHandlerMYSPIL(header, body) {
    return axios
      .post(
        `${getEnv(process.env.MYSPILCOM_GOLANG_API_URL)}/requesthandler`,
        JSON.stringify(body),
        {
          headers: header,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },

  getUserIP() {
    return axios
      .get("https://api64.ipify.org?format=json")
      .then((response) => response.data.ip)
      .catch((error) => {
        console.error("Error fetching IP info:", error);
      });
  },
};

export default mainAPI;
